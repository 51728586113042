<!-- BCT: Added by BCT -->
<!-- BCT: Added by BCT -->
<!-- BCT: Added by BCT -->
<template>
  <div>
    <edit-feature-and-amenity
      v-if="dialog"
      :property_id="property_id"
      :activeTab="activeTab"
      v-model="dialog"
      @close="dialog = false"
    ></edit-feature-and-amenity>

    <!-- // Restricted Permission warning popup -->
    <hb-modal
      v-model="showNoPermissionDialog"
      size="large"
      title="Unable to Create Custom Feature and Amenity"
      :footerOff="true"
      confirmation
      @close="showNoPermissionDialog = false"
      show-help-link
    >
      <template v-slot:content>
        <div class="px-6 py-4">
          <div class="pb-2">The signed in account does not have permission to create custom feature and amenity.</div>
          <div>To create custom feature and amenity, contact your administrator to enable permission.</div>
        </div>
      </template>
    </hb-modal>
    <!-- Restricted Permission warning popup // -->
  </div>
</template>

<script>
import EditFeatureAndAmenity from "./EditFeatureAndAmenity.vue";
import { EventBus } from "../../../EventBus.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CustomFeatureAndAmenity",
  components: {
    EditFeatureAndAmenity
  },
  props: {
    activeTab: {
      type: Object
    },
    property_id: {
      type: String
    }
  },

  data() {
    return {
      dialog: false,
      showNoPermissionDialog: false,
    };
  },

  created() {
    EventBus.$on("createCustomFeatureAndAmenityEvent", this.eventCreateCustomFeatureAndAmenityHandler);
  },

  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission'
    }),
  },

  methods: {
    ...mapActions({
      fetchFeatures: "featureAndAmenitiesStore/fetchFeatures"
    }),

    eventCreateCustomFeatureAndAmenityHandler() {
      if (this.hasPermission('create_custom_feature_amenity')) {
        this.dialog = true;
      } else {
        this.showNoPermissionDialog = true;
      }
    },
  },

  destroyed() {
    EventBus.$off("createCustomFeatureAndAmenityEvent");
  },

};
</script>

<style></style>
