<!-- BCT: Added by BCT -->
<!-- BCT: Added by BCT -->
<!-- BCT: Added by BCT -->
<template>
  <div>
    <div v-for="(amenities, category, index) in amentiyCategoryMap" :key="index">
      <hb-form :label="category" :full="true">
        <v-row :key="index" v-for="(amenity, index) in amenities">
          <v-col :class="['pt-1', index === amenities.length - 1 ? 'pb-0' : 'pb-1']">
            <span>{{ amenity.feature }}</span>
          </v-col>
          <v-col :class="['pt-1', index === amenities.length - 1 ? 'pb-0' : 'pb-1']">
            <v-text-field
              v-if="['text', 'string'].includes(amenity.options.type)"
              v-model="amenity.property_default_value"
              placeholder="Enter text"
              hide-details
              v-validate="'required'"
              :error-messages="errors.collect(`${category + amenity.feature}`)"
              :id="amenity.feature"
              :name="amenity.feature"
            />
            <v-text-field
              v-else-if="(amenity.options.type == 'integer')"
              v-model="amenity.property_default_value"
              placeholder="Enter value"
              hide-details
              v-validate="'required'"
              :error-messages="errors.collect(`${category + amenity.feature}`)"
              :id="amenity.feature"
              :name="amenity.feature"
            />
            <v-select
              v-else-if="amenity.options.type == 'select'"
              :items="amenity.options.options"
              v-model="amenity.property_default_value"
              item-value="id"
              item-text="name"
              placeholder="Select a value"
              v-validate="'required'"
              hide-details
              :error-messages="errors.collect(`${category + amenity.feature}`)"
              :id="amenity.feature"
              :name="amenity.feature"
              style="width: 200px"
            >
            </v-select>
            <v-radio-group
              v-else
              row
              v-model="amenity.property_default_value"
              class="ma-0 pa-0"
              v-validate="'required'"
              hide-details
              :error-messages="errors.collect(`${category + amenity.feature}`)"
              :id="amenity.feature"
              :name="amenity.feature"
            >
              <hb-radio value="Yes" class="pb-3">
                <template v-slot:label>
                  <span class="text-capitalize">Yes</span>
                </template>
              </hb-radio>
              <hb-radio value="No" class="pb-3">
                <template v-slot:label>
                  <span class="text-capitalize">No</span>
                </template>
              </hb-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </hb-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefineValues",
  props: {
    amenityList: {
      default: function() {
        return [];
      },
      type: Array
    }
  },
  data() {
    return {
      amentiyCategoryMap: {},
      // Used in Parent component.
      deletedAmenties: [],
      addedAmenties: [],
      allAmenities: {}
    };
  },
  created() {
    this.amenityList.forEach(item => {
      if (item.amenities && item.amenities.length > 0) {
        item.amenities.forEach(amenity => {
          if (this.allAmenities[item.category]) {
            this.allAmenities[item.category].push(amenity);
          } else {
            this.allAmenities[item.category] = [amenity];
          }
          if (amenity.checked ) {
            if (this.amentiyCategoryMap[item.category]) {
              this.amentiyCategoryMap[item.category].push(amenity);
            } else {
              this.amentiyCategoryMap[item.category] = [amenity];
            }
            if (!amenity.property_id) {
              this.addedAmenties.push(amenity.feature);
            }
          } else {
            if (amenity.property_id ) {
              this.deletedAmenties.push(amenity.feature);
            }
          }
        });
      }
    });
  }
};
</script>

<style lang="scss" scoped></style>
