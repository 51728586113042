<!-- Added By BCT -->
<!-- Added By BCT -->
<!-- Added By BCT -->
<template>
  <div v-if="amenityList.length > 0">
    <div v-for="(item, index) in amenityList" :key="'item' + index">
      <hb-form :label="item.category">
        <v-row v-for="(amenity, i) in item.amenities" :key="'amenity' + i" class="mx-0 px-0">
          <hb-checkbox
              @change="amenity.checked = $event"
              :disabled="(amenity.property_id && !!amenity.amenity_assigned_count)"
              :label="amenity.feature"
              :value="amenity.checked"
            />
            <hb-tooltip v-if="(amenity.property_id && !!amenity.amenity_assigned_count)">
              <template v-slot:body>
                <div style="max-width: 200px">
                  To remove this feature or amenity please use the ellipsis on the right end of the Spaces Features and Amenities from Property Settings
                </div>
              </template>
            </hb-tooltip>
        </v-row>
      </hb-form>

      <!-- hb-checkbox improvement
      <hb-form :label="item.category">
        <hb-checkbox
          v-for="(amenity, i) in item.amenities"
          :key="'amenity' + i"
          @change="amenity.checked = $event"
          :disabled="(amenity.property_id && !!amenity.amenity_assigned_count)"
          :label="amenity.feature"
          :value="amenity.checked"
          :tooltip-body="(amenity.property_id && !!amenity.amenity_assigned_count) ? 'To remove this feature or amenity please use the ellipsis on the right end of the Spaces Features and Amenities from Property Settings' : ''"
        />
      </hb-form>
      -->

    </div>
  </div>
</template>

<script>
export default {
  name: "DefineValues",
  props: {
    amenityList: {
      default: function() {
        return [];
      },
      type: Array
    }
  },
  methods: {
    async validateForm() {
      let valid = false;
      this.amenityList.map(item => {
        item.amenities.map(a => {
          if (a.checked) {
            valid = true;
          }
        });
      });
      return valid;
    }
  }
};
</script>
