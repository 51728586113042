<!-- THIS COMPONENT IS DEPRECATED. DO NOT USE THIS FILE ANYMORE. Use HbEmptyState component instead. -->
<!-- HbEmptyState is registered globally in hummingbird in the main.js so you do not need to import the file manually to use it. -->
<!-- Just call it like <hb-empty-state message="Whatever"></hb-empty-state> or refer to design system documentation pages -->

<!-- THIS COMPONENT IS DEPRECATED. DO NOT USE THIS FILE ANYMORE. Use HbEmptyState component instead. -->
<!-- HbEmptyState is registered globally in hummingbird in the main.js so you do not need to import the file manually to use it. -->
<!-- Just call it like <hb-empty-state message="Whatever"></hb-empty-state> or refer to design system documentation pages -->

<!-- THIS COMPONENT IS DEPRECATED. DO NOT USE THIS FILE ANYMORE. Use HbEmptyState component instead. -->
<!-- HbEmptyState is registered globally in hummingbird in the main.js so you do not need to import the file manually to use it. -->
<!-- Just call it like <hb-empty-state message="Whatever"></hb-empty-state> or refer to design system documentation pages -->

<!-- THIS COMPONENT IS DEPRECATED. DO NOT USE THIS FILE ANYMORE. Use HbEmptyState component instead. -->
<!-- HbEmptyState is registered globally in hummingbird in the main.js so you do not need to import the file manually to use it. -->
<!-- Just call it like <hb-empty-state message="Whatever"></hb-empty-state> or refer to design system documentation pages -->

<!-- THIS COMPONENT IS DEPRECATED. DO NOT USE THIS FILE ANYMORE. Use HbEmptyState component instead. -->
<!-- HbEmptyState is registered globally in hummingbird in the main.js so you do not need to import the file manually to use it. -->
<!-- Just call it like <hb-empty-state message="Whatever"></hb-empty-state> or refer to design system documentation pages -->

<!-- THIS COMPONENT IS DEPRECATED. DO NOT USE THIS FILE ANYMORE. Use HbEmptyState component instead. -->
<!-- HbEmptyState is registered globally in hummingbird in the main.js so you do not need to import the file manually to use it. -->
<!-- Just call it like <hb-empty-state message="Whatever"></hb-empty-state> or refer to design system documentation pages -->

<!-- THIS COMPONENT IS DEPRECATED. DO NOT USE THIS FILE ANYMORE. Use HbEmptyState component instead. -->
<!-- HbEmptyState is registered globally in hummingbird in the main.js so you do not need to import the file manually to use it. -->
<!-- Just call it like <hb-empty-state message="Whatever"></hb-empty-state> or refer to design system documentation pages -->

<template>
    <div class="empty-state-container" v-bind:style="{ background: backgroundColor }" >
        <span v-if="header" class="font-weight-medium subdued" >{{header}}</span>
        <p class="hb-text-night-light empty-state-message-wrapper">{{message}}</p>
        <div class="mt-3" v-if="showBtn && btnTxt">
            <hb-btn :disabled="disabled" @click="$emit('clicked')" :color="btnColor || 'primary' ">{{btnTxt}}</hb-btn>
        </div>
    </div>
</template> 
 
<script type="text/babel">
    export default {
        name: "EmptyStateContainer",
        props: {
            disabled: {
                type: Boolean
            },
            message: {
                type: String
            },
            btnColor: {
                type: String
            },
            btnTxt: {
                type: String
            },
            showBtn : { 
                type: Boolean, 
                default: true,
            },
            header:{
                type: String
            },
            backgroundColor:{
                type:String
            }
        },
        data() {
            return {}
        },
    
    }

</script>


<style scoped>
    .empty-state-container{
        text-align: center;
        padding: 24px;
        border: 1.6px dashed #c4cdd5;
        border-radius: 4px;
    }
    .empty-state-message-wrapper {
        margin-bottom: 2px;
    }
    .subdued {
        color: #a5adb4;
    }

</style>